/**
 * ask user before deleting entry if operation is safe.
 */
$("form>input[name='_method'][value='DELETE']~button").parent().not('.toggle-visibility').submit(function () {
    if (confirm('Wirklich löschen?')) {
        $(this).parents('form').submit();
        return true;
    }
    else {
        event.preventDefault();
        return false;
    }
});


var stepCounter = parseInt($('input:hidden#steps_value').val());
$("#newStep").click(function () {
    stepCounter = stepCounter + 1;
    $('input:hidden#steps_value').val(stepCounter);
    $("#steps").append(
        '<div class="step' + stepCounter + '">' +
        '<div class="form-group"> <label class="col-lg-3 control-label"><h4>Schritt ' + stepCounter + ':</h4></label> <div class="col-lg-8"><a id="delete_step" href="#" class="btn btn-circle btn-default btn-icon-only"><i class="fa fa-trash"></i></a></div></div>' +
        '<div class="form-group"><label class="col-lg-3 control-label" for="target' + stepCounter + '">Target *</label><div class="col-lg-8"><input type="text" name="target' + stepCounter + '" id="target' + stepCounter + '" class="form-control" placeholder="Target"></div></div>' +
        '<div class="form-group"><label class="col-lg-3 control-label" for="content' + stepCounter + '">Inhalt *</label><div class="col-lg-8"><textarea name="content' + stepCounter + '" rows="3" cols="50" id="content' + stepCounter + '" class="form-control" placeholder="Inhalt"></textarea></div></div>' +
        '<div class="form-group"><label class="col-lg-3 control-label" for="placement' + stepCounter + '">Ausrichtung</label><div class="col-lg-8"><select name="placement' + stepCounter + '" id="placement' + stepCounter + '" class="form-control"><option value="1" selected="">Oben</option><option value="2">Unten</option><option value="3">Links</option><option value="4">Rechts</option></select></div></div>' +
        '<div class="form-group"><label class="col-lg-3 control-label" for="onnext' + stepCounter + '">OnNext Funktion</label><div class="col-lg-8"><textarea name="onnext' + stepCounter + '" rows="3" cols="50" id="onnext' + stepCounter + '" class="form-control" placeholder="OnNext JavaScript Funktion"></textarea></div></div>' +
        '</div>'
    );
});

$(document).on('click', '#delete_step', function (e) {
    var step = '.step' + stepCounter;
    $(step).hide();
    stepCounter = stepCounter - 1;
    $('input:hidden#steps_value').val(stepCounter);
});

$("#tutorial_button").click(function () {
    hopscotch.startTour(tour);
});


/**
 * markdown handling editor interafce.
 */

var fileURL = "";
var linkTitle = "";

$('textarea.markdown').markdown({
    'hiddenButtons': ['cmdImage', 'cmdQuote', 'cmdCode', 'cmdListO'],
    additionalButtons: [
        [
            {
                name: "groupFont",
                data: [{
                    name: "cmdCic",
                    toggle: true, // this param only take effect if you load bootstrap.js
                    title: "CI Farbe (färbt den Text)",
                    icon: "glyphicon glyphicon-tint",
                    callback: function (e) {
                        // Replace selection
                        var cursor,
                            selected = e.getSelection();
                        // transform selection and set the cursor into chunked text
                        e.replaceSelection(`%cic%${selected.text}%cic%`)
                        cursor = selected.start
                        // Set the cursor
                        e.setSelection(cursor + 5, cursor + 5 + selected.text.length)
                    }
                }]
            },
            {
                name: "groupFont",
                data: [{
                    name: "cmdSmall",
                    toggle: false, // this param only take effect if you load bootstrap.js
                    title: "Kleiner Text",
                    icon: "fa fa-minus",
                    callback: function (e) {
                        // Replace selection
                        var cursor,
                            selected = e.getSelection()
                        // transform selection and set the cursor into chunked text
                        e.replaceSelection(`%small%${selected.text}%small%`)
                        cursor = selected.start;
                        // Set the cursor
                        e.setSelection(cursor + 7, cursor + 7 + selected.text.length);
                    }
                }]
            },
            {
                name: "groupFont",
                data: [{
                    name: "cmdYoutube",
                    toggle: false, // this param only take effect if you load bootstrap.js
                    title: "Youtube Video einbetten",
                    icon: "fa fa-youtube",
                    callback: function (e) {
                        // Replace selection
                        var cursor,
                            selected = e.getSelection(),
                            videoid = prompt("Youtube Video-ID");
                        // transform selection and set the cursor into chunked text
                        e.replaceSelection(`%youtube%${videoid}%youtube%`)
                        cursor = selected.start
                        // Set the cursor
                        e.setSelection(cursor + 9, cursor + 9 + selected.text.length)
                    }
                }]
            },
            {
                name: "groupFont",
                data: [{
                    name: "cmdVimeo",
                    toggle: false, // this param only take effect if you load bootstrap.js
                    title: "Vimeo Video einbetten",
                    icon: "fa fa-vimeo",
                    callback: function (e) {
                        // Replace selection
                        var cursor,
                            selected = e.getSelection(),
                            videoid = prompt("Vimeo Video-ID");
                        // transform selection and set the cursor into chunked text
                        e.replaceSelection(`%vimeo%${videoid}%vimeo%`)
                        cursor = selected.start
                        // Set the cursor
                        e.setSelection(cursor + 9, cursor + 9 + selected.text.length)
                    }
                }]
            },
            {
                name: "groupFont",
                data: [{
                    name: "cmdDownload",
                    toggle: false, // this param only take effect if you load bootstrap.js
                    title: "Download einbetten",
                    icon: "fa fa-file",
                    callback: function (e) {

                        var cursor,
                            selected = e.getSelection();

                        $.ajax({
                            url: "/file-manager/files",
                            type: "get",
                            dataType: 'json',
                            success: function (data) {
                                $("#files").html("");

                                if (data.length == 0) {
                                    $("#files").append("<p>Keine Dateien gefunden.</p>");
                                } else {
                                    $.each(data, function (i, item) {


                                        var div = '<div class="radio"><label class="radio"><input type="radio" name="files" value="' + data[i].url + '">';
                                        div += '<i class="fa ' + data[i].icon + '" style="margin-right: 5px;"></i>';
                                        div += data[i].name;
                                        div += '</label></div>';

                                        $("#files").append(div);
                                    });
                                }

                                $('#fileModal').modal();
                            },
                            error: function (error) {
                                alert("Dateimanager ist nicht aktiviert!")
                            }
                        });

                        $('#fileModal').on('hide.bs.modal', function (a) {
                            if (fileURL != "" && linkTitle != "") {
                                fileURL = '%blank%[' + linkTitle + '](' + fileURL + ')%blank%';

                                e.replaceSelection(fileURL);

                                fileURL = "";
                                linkTitle = "";
                            }
                        });
                    }
                }]
            }
        ]
    ]
});

$(document.body).on('click', '#addFile', function (e) {
    e.preventDefault();

    var formData = $("#fileForm").serializeArray();

    if(typeof formData[0]  === "undefined" || typeof formData[1]  === "undefined" || !$("#linkTitle").val()){
        alert("Datei und Title muss angegeben sein!");
    }else{
        fileURL = formData[0].value;
        linkTitle = formData[1].value;

        $("#fileForm")[0].reset();

        $('#fileModal').modal('hide');
    }
});


$('input.switch').bootstrapSwitch();
$('input.datepicker').datepicker({
    language: "de",
    calendarWeeks: true
});
$('input.datetimepicker').datetimepicker({
    locale: 'de'
});

/**
 * client switch handling.
 */
$('select#clientSwitcher').change(function () {
    $('form#clientSwitch').submit();
});

/**
 * sorting the old way.
 */
$(".go-up-in-sorting, .go-down-in-sorting").on("click", function (event) {
    event.preventDefault();

    $.ajax({
        "url": $(this).attr("href")
    }).done(function () {
        document.location.reload(true);
    });

    return false;
});

/**
 * sorting the new way.
 */
$(".make-list-sortable").each(function (index, element) {
    Sortable.create(element, {
        // dragging ended
        onEnd: function (/**Event*/evt) {
            var stepsToMove = evt.newIndex - evt.oldIndex;
            var sortingBox = $(evt.item).find('.sorting-box');
            var url = sortingBox.data("sorting-url").replace("%sorting-amount%", stepsToMove);
            var actualSortingPosition = parseInt(sortingBox.data("sorting"));
            var newSortingPosition = actualSortingPosition + stepsToMove;

            //update sorting
            /*
             sortingBox.data("sorting-url", newSortingPosition);
             var sortingButton = $(evt.item).find('.sorting-button');
             sortingButton.html(sortingButton.html().replace(actualSortingPosition, newSortingPosition));
             */

            //update sorting in backend
            $.ajax({
                "url": url
            }).done(function () {
                document.location.reload(true);
            });
        },
    });
});

/**
 * character counter
 *
 */

$('.charCount, .charCount140').keyup(function () {
    var maxLength = $(this).attr('maxlength');
    var count = $(this).val().length;
    var info = $(this).parents('.form-group').find('.help-block');
    var split = info.text().split(' ');
    info.text(split[0] + ' ' + count + '/' + maxLength);
});


Dropzone.options.realDropzone = {
    init: function () {
        this.on("complete", function (file) {
            if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                $('#dropzone-success').show()
            }
        });
    }
};

$.fn.datepicker.dates['de'] = {
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    daysShort: ["Son", "Mon", "Din", "Mitt", "Don", "Fre", "Sam"],
    daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    today: "Today",
    clear: "Clear",
    format: "dd.mm.yyyy",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 1
};
